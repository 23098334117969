import {
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  chakra,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function AdminSalesView() {
  const { t } = useTranslation();
  return (
    <Flex direction="column" h="full">
      <HStack justifyContent="space-between" my={2}>
        <chakra.h3 fontSize="2xl" fontWeight="bold" textAlign="center">
          {t('admin.orders.title')}
        </chakra.h3>
      </HStack>
      <Flex h="100%" pb={{ base: 8, md: 8 }}>
        <Tabs h="100%" w="100%" variant="line" size="md" isFitted>
          <TabList>
            <Tab>Sales order</Tab>
            <Tab>Event booking</Tab>
          </TabList>

          <TabPanels h="full">
            <TabPanel h="full" px={0}>
              <iframe
                className="clickup-embed clickup-dynamic-height"
                src="https://forms.clickup.com/24315787/f/q61wb-3715/EBUFD0VE930SNSV8GW"
                width="100%"
                height="100%"
                style={{
                  background: 'transparent',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              ></iframe>
              <script
                async
                src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
              ></script>
            </TabPanel>
            <TabPanel w="full" h="full">
              <iframe
                className="clickup-embed clickup-dynamic-height"
                src="https://forms.clickup.com/24315787/f/q61wb-2935/5Y1CWPQ7VP5OX92DCN"
                width="100%"
                height="100%"
                style={{
                  background: 'transparent',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              ></iframe>
              <script
                async
                src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
              ></script>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  );
}
