import { Meeting } from '@/services/API';

export enum MEETING_STATUS {
  IDLE, // meeting is not ready yet
  DEMO, // demo meetings are not linked to date, they are always ready
  TESTING,
  READY,
  STARTED,
  USER_RATING,
  ENDED,
  CANCELED,
  ARCHIVED,
}

export const getMeetingStatus = (meeting: Meeting): MEETING_STATUS => {
  const now = new Date().getTime();
  const eventDateDate = new Date(meeting.eventDate);
  eventDateDate.setHours(0, 0, 0, 0);
  const eventDate = eventDateDate.getTime();
  const endDate = meeting.endDate ? new Date(meeting.endDate).getTime() : null;

  if (meeting.isDemo) return MEETING_STATUS.DEMO;
  if (meeting.isArchived && !meeting.startDate) return MEETING_STATUS.CANCELED;
  if (meeting.isArchived) return MEETING_STATUS.ARCHIVED;
  if (meeting.isInTesting) return MEETING_STATUS.TESTING;
  // meeting is on NPS if
  if (endDate && now > endDate) return MEETING_STATUS.ENDED;
  // end date is set while sending to NPS (for non testing meetings)
  if (endDate) return MEETING_STATUS.USER_RATING;
  // STARTED TODO:
  if (meeting.startDate) return MEETING_STATUS.STARTED;
  // ready if event is today
  if (now > eventDate) return MEETING_STATUS.READY;

  return MEETING_STATUS.IDLE;
};

export const getMeetingstatusColor = (status: MEETING_STATUS): string => {
  const colors: { [key in MEETING_STATUS]: string } = {
    [MEETING_STATUS.DEMO]: 'gray',
    [MEETING_STATUS.IDLE]: 'yellow',
    [MEETING_STATUS.TESTING]: 'orange',
    [MEETING_STATUS.READY]: 'green',
    [MEETING_STATUS.STARTED]: 'red',
    [MEETING_STATUS.USER_RATING]: 'green',
    [MEETING_STATUS.ENDED]: 'grey',
    [MEETING_STATUS.CANCELED]: 'grey',
    [MEETING_STATUS.ARCHIVED]: 'grey',
  };
  return colors[status];
};

export const getMeetingStatusText = (status: MEETING_STATUS): string => {
  const trads: { [key in MEETING_STATUS]: string } = {
    [MEETING_STATUS.DEMO]: '⚡️ DEMO',
    [MEETING_STATUS.IDLE]: 'NOT READY',
    [MEETING_STATUS.TESTING]: '🧪 TESTING',
    [MEETING_STATUS.READY]: '🚀 READY',
    [MEETING_STATUS.STARTED]: '🏁 STARTED',
    [MEETING_STATUS.USER_RATING]: 'Users are voting',
    [MEETING_STATUS.ENDED]: '✅ Finished',
    [MEETING_STATUS.CANCELED]: 'Canceled',
    [MEETING_STATUS.ARCHIVED]: 'Archived',
  };
  return trads[status];
};
