import { Client, Meeting } from '@/services/API';
import {
  Box,
  Button,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  useDisclosure,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import getColumns from './columns';
import useAdminClientTableStore from './AdminClientListTableStore';
import { useState } from 'react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { fuzzyFilter } from '@/utils/helpers/fuzzyFilter';
import CreateClientForm from '../CreateClientForm/CreateClientForm';
import Filters from '@/ui/TanstackTable/Filter/Filters';
import GlobalFilter from '@/ui/TanstackTable/Filter/GlobalFilter/GlobalFilter';
import ActiveFilters from '@/ui/TanstackTable/Filter/ActiveFilters/ActiveFilters';
import TanstackTableView from '@/ui/TanstackTable/TanstackTableView';
import Pagination from '@/ui/TanstackTable/Pagination';
import ColumnVisibility from '@/ui/TanstackTable/Columns/ColumnVisibility';
import { FilterType } from '@/ui/TanstackTable/Filter/NewFilterComponent';

type Props = {
  data: Client[];
  meetingList: Meeting[];
  handleDeleteClient: (client: Client) => void;
};

export default function AdminClientListView({
  data,
  meetingList,
  handleDeleteClient,
}: Props) {
  const { t } = useTranslation();

  const {
    isOpen: isFormOpen,
    onOpen: openForm,
    onClose: closeForm,
  } = useDisclosure();

  const columns = getColumns({
    t,
    onDeleteClientClick: handleDeleteClient,
    meetingList,
  });
  const [
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    columnSizing,
    setColumnSizing,
  ] = useAdminClientTableStore((state) => [
    state.columnFilters,
    state.setColumnFilters,
    state.columnVisibility,
    state.setColumnVisibility,
    state.columnSizing,
    state.setColumnSizing,
  ]);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  const table = useReactTable({
    data: data,
    initialState: {
      pagination: {
        pageSize: 10,
      },
      sorting: [{ id: 'createdAt', desc: true }],
    },
    state: {
      columnSizing,
      columnVisibility,
      columnFilters,
      globalFilter,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: (updater) => {
      const newVisibility =
        typeof updater === 'function' ? updater(columnVisibility) : updater;
      setColumnVisibility(newVisibility);
    },
    onColumnSizingChange: (updater) => {
      const newSizing =
        typeof updater === 'function' ? updater(columnSizing) : updater;
      setColumnSizing(newSizing);
    },
    defaultColumn: {},
    columnResizeMode: 'onEnd',
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: columns,
  });
  const filterTypeMappings: Partial<Record<keyof Client, FilterType>> = {
    createdAt: FilterType.DATE,
  };
  const possibleFilterOptions = {};

  return (
    <Box maxW="full" p={{ base: 3, md: 3 }}>
      <HStack justifyContent="space-between" my={2}>
        <chakra.h3 fontSize="2xl" fontWeight="bold" textAlign="center">
          {t('admin.clients.clientsList.title')}{' '}
        </chakra.h3>
        <Popover
          placement="bottom-start"
          isOpen={isFormOpen}
          onClose={closeForm}
        >
          <PopoverTrigger>
            <Button
              background="blue.400"
              color="white"
              size="md"
              onClick={openForm}
            >
              {t('admin.clients.button.createNew')}
            </Button>
          </PopoverTrigger>
          <PopoverContent w="auto" padding="10px" boxShadow="lg">
            <PopoverBody>
              <CreateClientForm closeForm={closeForm} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>
      <>
        <Flex gap="20px" justifyContent="center" alignItems="center" mb="4">
          <VStack w="full">
            <HStack w="full">
              <ColumnVisibility table={table} />
              <Filters
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                table={table}
                filterTypeMapping={filterTypeMappings}
                filterOptionsMapping={possibleFilterOptions}
              />
              <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                maxWidth="250px"
              />
              <ActiveFilters
                columnFilters={columnFilters}
                removeItemFunc={(elem) => {
                  setColumnFilters(
                    columnFilters.filter((f) => f.id !== elem.id)
                  );
                }}
                filterTypeMapping={filterTypeMappings}
              />
              <Spacer />
              <Pagination table={table} />
            </HStack>
          </VStack>
        </Flex>

        <TanstackTableView table={table} />
      </>
    </Box>
  );
}
