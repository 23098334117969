import APP_CONSTANTS from '@/utils/constants/app.constants';
import { IsLocalHost } from '@/utils/helpers/isLocalHost';
import { PatientCase } from '@/utils/types/zod/patientCaseSchema';
import { useEffect, useState } from 'react';

type Props = {
  currentCase: PatientCase | undefined;
  isNPSScorePage: boolean;
};

export default function useShowDisclaimer(props: Props) {
  const [startedAt, setStartedAt] = useState<number>(0);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const disclaimerDuration = IsLocalHost()
    ? APP_CONSTANTS.DISCLAIMER_TIMEOUT.localhost
    : APP_CONSTANTS.DISCLAIMER_TIMEOUT.default;

  const endsAt = startedAt + disclaimerDuration;

  // reset disclaimer each time we change the case
  useEffect(() => {
    if (props.currentCase && !props.isNPSScorePage) {
      setShowDisclaimer(true);
      setStartedAt(Date.now());
      // in case of hard reload, we do not show diclaimer again
      if (localStorage.getItem('reloaded') === 'true') {
        setShowDisclaimer(false);
        localStorage.removeItem('reloaded');
        return;
      }

      setTimeout(() => {
        setShowDisclaimer(false);
      }, disclaimerDuration);
    }
  }, [props.currentCase]);

  return { showDisclaimer, startedAt, endsAt };
}
