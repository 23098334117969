import { useUserList } from '@/services/hooks/useUserList';
import { AdminUserListView } from './AdminUserListView';
import { MOCK_USER_PREFIX } from '@/features/meeting-stats/mocking-tool/mockUser/users.create';
import useUserListClients from '@/services/hooks/useUserListClients';
import { useMemo } from 'react';
import { PageLoading } from '@/ui/PageLoading';
import { AdminUserListItemType } from './columns';
import { useAllTeamsList } from '@/features/teams/hooks/useAllTeamsList';

export const AdminUserListClient = () => {
  const { userListToClientNames } = useUserListClients();
  const userList = useUserList();
  const allTeams = useAllTeamsList();

  const parsedUserList: AdminUserListItemType[] = useMemo(() => {
    if (!userList.data || !allTeams.data) {
      return [];
    }
    return userList.data
      .filter((el) => !el.email.startsWith(MOCK_USER_PREFIX))
      .map((el) => {
        const teams = allTeams.data.filter((team) => el.teamID === team.id);

        return {
          ...el,
          last_seen: el.last_seen ?? el.createdAt,
          teamsText: teams.map((team) => team.teamName).join(', '),
          teams,
        } satisfies AdminUserListItemType;
      });
  }, [userList, allTeams]);

  if (userList.isLoading || !userList.data) {
    return <PageLoading />;
  }

  return (
    <AdminUserListView
      data={parsedUserList}
      userListToClientNames={userListToClientNames}
    />
  );
};
