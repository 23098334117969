import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '@/ui/Layout/PageLayout';
import { ROUTES } from '@/routes/Routes';
import Sidebar from '@/pages/admin/components/sidebar';
import CaseEditPage from './CaseEditPage';
import { PageLoading } from '@/ui/PageLoading';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { AdminUserListClient } from './components/AdminUserList/AdminUserListClient';
import AdminMeetingListClient from './components/AdminMeetingList/AdminMeetingListClient';
import CaseListClient from './components/CaseList/CaseListClient';
import AdminClientListClient from './components/AdminClientList/AdminClientListClient';
import AdminCaseClusterListClient from './components/AdminCaseClusterList/AdminCaseClusterListClient';
import AdminClickupListClient from './components/AdminClickupList/AdminClickupListClient';
import AdminSalesView from './components/AdminSales/AdminSalesView';
import AdminTeamListClient from './components/AdminTeamList/AdminTeamListClient';

const AdminPage = () => {
  const { user } = useUserAuthenticationContext();

  // loading user
  if (!user) {
    return <PageLoading />;
  }

  return (
    <Layout>
      <Sidebar>
        <Routes>
          <Route
            path={ROUTES.ADMIN_USER_LIST.replace('admin/', '')}
            element={<AdminUserListClient />}
          />
          <Route
            path={ROUTES.ADMIN_CLIENTS.replace('admin/', '')}
            element={<AdminClientListClient />}
          />
          <Route
            path={ROUTES.ADMIN_TEAMS.replace('admin/', '')}
            element={<AdminTeamListClient />}
          />
          <Route
            path={ROUTES.ADMIN_CASE_LIST.replace('admin/', '')}
            element={<CaseListClient />}
          />
          <Route
            path={ROUTES.ADMIN_CASE_EDIT.replace('admin/', '')}
            element={<CaseEditPage />}
          />
          <Route
            path={ROUTES.ADMIN_MEETING_LIST.replace('admin/', '')}
            element={<AdminMeetingListClient />}
          />
          <Route
            path={ROUTES.ADMIN_CASE_CLUSTER_LIST.replace('admin/', '')}
            element={<AdminCaseClusterListClient />}
          />
          <Route
            path={ROUTES.ADMIN_CLICKUP.replace('admin/', '')}
            element={<AdminClickupListClient />}
          />
          <Route
            path={ROUTES.ADMIN_SALES.replace('admin/', '')}
            element={<AdminSalesView />}
          />
          {/* handle default index => users */}
          <Route index element={<Navigate to={ROUTES.ADMIN_MEETING_LIST} />} />
        </Routes>
      </Sidebar>
    </Layout>
  );
};

export default AdminPage;
