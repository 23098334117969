import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

type Props = {
  startedAt: number;
  endsAt: number;
};

export const TimerLoader = ({ startedAt, endsAt }: Props) => {
  const currentTime = Date.now();
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage(((currentTime - startedAt) / (endsAt - startedAt)) * 100);
    }, 10);

    return () => clearInterval(interval);
  }, [currentTime, startedAt, endsAt]);
  return (
    <Box height="3px" bg="transparent" width="100%">
      <Box bg="primary" width={`${percentage}%`} height="100%" />
    </Box>
  );
};
